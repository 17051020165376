
import store from "@/store";
import { Button, Table, Searchbar} from '@/ui/index';
import { defineComponent } from 'vue';
import { ProjectController } from '@/controller/';

export default defineComponent({
    name: "ProjectList",
    components: { Button, Searchbar, Table },
    data() {
        return {
            store,
            projectsTableData: {
                thFields: [
                    { title: this.$t("project.numberExternal"), property: 'numberExternal', type: 'string',  clickAction: "openDetail"}, 
                    { title: this.$t("project.numberInternal"), property: 'numberInternal', type: 'string',  clickAction: "openDetail"},
                    { title: this.$t("project.numberSerial"), property: 'numberSerial', type: 'string',  clickAction: "openDetail"},
                    { title: this.$t("label.title"), property: 'title', type: 'string',  clickAction: "openDetail"}, 
                    { title: this.$t("project.client"), property: 'customer.custName', type: 'string',  clickAction: "openCustomer"}, 
                    { title: this.$t("project.active"), property: 'active', type: 'boolean', action: "toggleActiveState"}, 
                    { title: this.$t("project.workingHours"), property: 'hours', type: 'string'}
                ],
                tbActions: [{ name: "openDetail", icon: store.getters.svgIcons.watch }, { name: "editProject", icon: store.getters.svgIcons.pencil }, { name: "confirmDelete", icon: store.getters.svgIcons.remove }]
            },
            projects: [] as Array<any>,
            searchInput: ""
        }
    },
    created() {
        this.handleGetProjects();
    },
    computed: {
        filteredProjects() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.projects.forEach((project : any) => {
                    (
                        JSON.stringify(project.title).toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        JSON.stringify(project.numberExternal).toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        JSON.stringify(project.numberInternal).toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        JSON.stringify(project.numberSerial).toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        ((project.customer) && JSON.stringify(project.customer.custName).toLowerCase().includes(this.searchInput.toLowerCase()))
                    ) && filtered.push(project);
                })
            } else {
                filtered = this.projects;
            }
            return filtered;
        }
    },
    methods:{
        async handleGetProjects() {
            const res = await ProjectController.fetchProjects();
            if(!res.error){
                this.projects = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleTableActionClicked(action : string, item:any) {
            switch(action) {
                case "openDetail":
                    this.handleOpenDetailView(item.uid)
                    break
                case "openCustomer":
                    console.log('openCustomer', item)
                    this.handleOpenCustomerView(item.customer.uid)
                    break
                case "confirmDelete":
                    this.handleConfirmDelete(item)
                    break
                case "editProject":
                    this.handleEdit(item.uid)
                    break
                case "toggleActiveState":
                    this.toggleActiveState(item)
                    break
            }
        },
        handleShowCreateForm() {
            this.$router.push("/administration/project/form/0");
        },
        handleOpenDetailView(uid : number) {
            this.$router.push("/administration/project/detail/" + uid);
        },
        handleEdit(uid:number) {
            this.$router.push("/administration/project/form/" + uid);
        },
        handleOpenCustomerView(uid : number) {
            this.$router.push("/administration/client/detail/" + uid);
        },
        handleConfirmDelete(item : any) {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteProjectText', { name: item.title }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete(item.uid) },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async toggleActiveState(item : any) {            
            const postData = {
                'uid': item.uid,
                'status': !item.active
            }
            const res = await ProjectController.toggleProject(postData);
            if (res.project) {
                    //workaround to have reactive list
                this.projects = [];
                this.$nextTick(() => {
                    this.projects = ProjectController.getProjects().items
                });
            }
        },
        async handleDelete(uid : number) {
            const res = await ProjectController.deleteProject(uid);
            if (!res.error) {
                this.projects = this.projects.filter(function(item : any) {
                   return item.uid != res.project.uid;
                });
                this.$notify(this.$t("success.projectDeleted"), { position: "top" });
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
